<template>
  <b-card v-if="studentInfo">
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img :src="ulrAvatar"  alt="Avatar" height="80"></b-img>
        </b-link>
      </b-media-aside>
    </b-media>

    <b-form class="mt-2">
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Mã sinh viên"
            label-for="student_code"
          >
            <b-form-input
              id="student_code"
              v-model="studentInfo.student_code"
              name="student_code"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Họ và tên"
            label-for="student_name"
          >
            <b-form-input
              id="student_name"
              v-model="studentInfo.student_name"
              name="student_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Giới tính"
            label-for="student_gender_detail"
          >
            <v-select
              v-model="studentInfo.gender"
              label="name"
              :options="allGenders"
              :reduce="option => +option.code"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="CMT/CCCD"
            label-for="student_identify_code"
          >
            <b-form-input
              id="student_identify_code"
              v-model="studentInfo.student_identify_code"
              name="student_identify_code"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Ngày sinh"
            label-for="student_birthday"
          >
            <flat-pickr
              v-model="studentInfo.student_birthday"
              :config="config"
              class="form-control"
              name="student_birthday"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Nơi sinh"
            label-for="student_birth_place"
          >
            <b-form-input
              id="student_birth_place"
              v-model="studentInfo.student_birth_place"
              name="student_birth_place"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Quê quán"
            label-for="student_hometown"
          >
            <b-form-input
              id="student_hometown"
              v-model="studentInfo.student_hometown"
              name="student_hometown"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Loại khuyết tật"
            label-for="student_disability"
          >
            <v-select
              v-model="studentInfo.disability"
              label="name"
              :options="allFactDisabilities"
              :reduce="option => option.code"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Quốc tịch"
            label-for="student_nationality"
          >
            <v-select
              v-model="studentInfo.nationality"
              label="name"
              :options="allFactNationalities"
              :reduce="option => option.code"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Tỉnh/thành phố"
            label-for="student_province"
          >
            <v-select
              v-model="studentInfo.province_code"
              label="name"
              :options="allFactProvinces"
              :reduce="option => option.code"
              @input="onProvinceChange"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Quận/huyện"
            label-for="student_district"
          >
            <v-select
              v-model="studentInfo.district_code"
              label="name"
              :options="allFactDistricts"
              :reduce="option => option.code"
              @input="onDistrictChange"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Phường/xã"
            label-for="student_ward"
          >
            <v-select
              v-model="studentInfo.ward_code"
              label="name"
              :options="allFactWards"
              :reduce="option => option.code"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Địa chỉ"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="studentInfo.address"
              name="address"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Email"
            label-for="student_email"
          >
            <b-form-input
              id="student_email"
              v-model="studentInfo.student_email"
              name="student_email"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Số điện thoại"
            label-for="student_mobile"
          >
            <b-form-input
              id="student_gender_detail"
              v-model="studentInfo.student_mobile"
              name="student_mobile"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Số bảo hiểm"
            label-for="student_social_insurance"
          >
            <b-form-input
              id="student_social_insurance"
              v-model="studentInfo.student_social_insurance"
              name="student_social_insurance"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Dân tộc"
            label-for="student_ethnic"
          >
            <v-select
              v-model="studentInfo.ethnic_code"
              label="name"
              :options="allFactEthnics"
              :reduce="option => option.code"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Ngày cấp CCCD"
            label-for="date_of_issuance"
          >
            <flat-pickr
              v-model="studentInfo.date_of_issuance"
              :config="config"
              class="form-control"
              name="date_of_issuance"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Hộ chiếu"
            label-for="passport_code"
          >
            <b-form-input
              id="passport_code"
              v-model="studentInfo.passport_code"
              name="passport_code"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Mã số thẻ bảo hiểm y tế"
            label-for="health_insurance_card_number"
          >
            <b-form-input
              id="health_insurance_card_number"
              v-model="studentInfo.health_insurance_card_number"
              name="health_insurance_card_number"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Thành phố/tỉnh liên hệ"
            label-for="province_code_contact"
          >
            <v-select
              v-model="studentInfo.province_code_contact"
              label="name"
              :options="allFactProvinces"
              :reduce="option => option.code"
              @input="onProvinceContactChange"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Quận/huyện liên hệ"
            label-for="district_code_contact"
          >
            <v-select
              v-model="studentInfo.district_code_contact"
              label="name"
              :options="allFactDistrictsContact"
              :reduce="option => option.code"
              @input="onDistrictContactChange"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Phường/xã liên hệ"
            label-for="ward_code_contact"
          >
            <v-select
              v-model="studentInfo.ward_code_contact"
              label="name"
              :options="allFactWardsContact"
              :reduce="option => option.code"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Địa chỉ chi tiết liên hệ"
            label-for="address_detail_contact"
          >
            <b-form-input
              id="address_detail_contact"
              v-model="studentInfo.address_detail_contact"
              name="address_detail_contact"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Thành phố/tỉnh thường trú"
            label-for="province_code_permanent_residence"
          >
            <v-select
              v-model="studentInfo.province_code_permanent_residence"
              label="name"
              :options="allFactProvinces"
              :reduce="option => option.code"
              @input="onProvincePermanentResidenceChange"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Quận/huyện thường trú"
            label-for="district_code_permanent_residence"
          >
            <v-select
              v-model="studentInfo.district_code_permanent_residence"
              label="name"
              :options="allFactDistrictsPermanentResidence"
              :reduce="option => option.code"
              @input="onDistrictPermanentResidenceChange"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Phường/xã thường trú"
            label-for="ward_code_permanent_residence"
          >
            <v-select
              v-model="studentInfo.ward_code_permanent_residence"
              label="name"
              :options="allFactWardsPermanentResidence"
              :reduce="option => option.code"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Địa chỉ thường trú chi tiết (số nhà, tên đường, tổ, ấp)"
            label-for="address_detail_parmanent_residence"
          >
            <b-form-input
              id="address_detail_parmanent_residence"
              v-model="studentInfo.address_detail_parmanent_residence"
              name="address_detail_parmanent_residence"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Hộ khẩu"
            label-for="permanent_residence"
          >
            <b-form-input
              id="permanent_residence"
              v-model="studentInfo.permanent_residence"
              name="permanent_residence"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Tôn giáo"
            label-for="student_religion"
          >
            <v-select
              v-model="studentInfo.religion_code"
              label="name"
              :options="allFactReligions"
              :reduce="option => option.code"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Ngày vào đoàn"
            label-for="student_date_youth_group"
          >
            <flat-pickr
              v-model="studentInfo.date_youth_group"
              :config="config"
              class="form-control"
              name="student_date_youth_group"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Ngày vào đảng"
            label-for="student_date_dangcongsan_group"
          >
            <flat-pickr
              v-model="studentInfo.date_dangcongsan_group"
              :config="config"
              class="form-control"
              name="student_date_dangcongsan_group"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Ngày vào đảng chính thức"
            label-for="student_date_dangcongsan_group_chinhthuc"
          >
            <flat-pickr
              v-model="studentInfo.date_dangcongsan_group_chinhthuc"
              :config="config"
              class="form-control"
              name="student_date_dangcongsan_group_chinhthuc"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Đã học lớp cảm tỉnh đảng"
            label-for="has_learn_party"
          >
            <v-select
              v-model="studentInfo.has_learn_party"
              :options="hasLearnPartySelect"
              :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Họ tên cha"
            label-for="father_full_name"
          >
            <b-form-input
              id="father_full_name"
              v-model="studentInfo.father_full_name"
              name="father_full_name"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Nghề nghiệp cha"
            label-for="father_occupation"
          >
            <b-form-input
              id="father_occupation"
              v-model="studentInfo.father_occupation"
              name="father_occupation"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Số điện thoại cha"
            label-for="father_phone_number"
          >
            <b-form-input
              id="father_phone_number"
              v-model="studentInfo.father_phone_number"
              name="father_phone_number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Ngày sinh cha"
            label-for="father_year_of_birth"
          >
            <flat-pickr
              v-model="studentInfo.father_year_of_birth"
              :config="config"
              class="form-control"
              name="father_year_of_birth"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Họ tên mẹ"
            label-for="mother_full_name"
          >
            <b-form-input
              id="mother_full_name"
              v-model="studentInfo.mother_full_name"
              name="mother_full_name"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Nghề nghiệp mẹ"
            label-for="mother_occupation"
          >
            <b-form-input
              id="mother_occupation"
              v-model="studentInfo.mother_occupation"
              name="mother_occupation"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Số điện thoại mẹ"
            label-for="mother_phone_number"
          >
            <b-form-input
              id="mother_phone_number"
              v-model="studentInfo.mother_phone_number"
              name="mother_phone_number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Ngày sinh mẹ"
            label-for="mother_year_of_birth"
          >
            <flat-pickr
              v-model="studentInfo.mother_year_of_birth"
              :config="config"
              class="form-control"
              name="mother_year_of_birth"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="text-right mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="onUpdateStudent"
            >
              <span class="text-nowrap text-right"> <feather-icon icon="CheckIcon" /> Lưu</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-left: 5px"
              @click="exportHoSo"
            >
              <span class="text-nowrap text-right"><feather-icon icon="DownloadIcon" /> Tải excel</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row />
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BFormGroup,
  BRow, BForm, BMediaAside, BMedia, BLink, BImg, BMediaBody, BFormFile, BCardText,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { HAS_LEARN_PARTY } from '@/const/status'
import flatPickr from 'vue-flatpickr-component'
import FlatpickrLanguages from 'flatpickr/dist/l10n'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { Flag } from '@/const/flag'

export default {
  name: 'CurriculumVitaeInfo',
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BCard,
    BCol,
    BRow,
    flatPickr,
    BMedia,
    BMediaAside,
    BLink,
    BImg,
    BMediaBody,
    BFormFile,
    BCardText,
  },
  props: {
    studentInfo: {
      type: Object,
      default: null,
    },
    studentId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isCreateReceipt: true,
      filter: {
        student_id: this.$route.params.id || this.studentId,
      },
      revenue_fields: [
        { key: 'semester_name', label: 'Kỳ học' },
        { key: 'subject_name', label: 'Môn học' },
        { key: 'total_credit_num', label: 'Số tín chỉ' },
        { key: 'amount', label: 'Số tiền' },
      ],
      revenue_items: [],
      exemption_fields: [
        { key: 'semester_name', label: 'Kỳ học' },
        { key: 'revenue_name', label: 'Loại miễn giảm' },
        { key: 'amount', label: 'Số tiền' },
      ],
      exemption_items: [],
      config: {
        wrap: true,
        enableTime: false,
        altInput: true,
        altFormat: 'Y-m-d',
        dateFormat: 'Y-m-d',
        locale: FlatpickrLanguages.vn,
        static: false,
        // minDate: 'today',
      },
      genderSelect: [],
      ulrAvatar: null,
    }
  },
  computed: {
    ...mapGetters({
      dataExport: 'curriculumVitae/dataExport',
      allFactEthnics: 'curriculumVitae/allFactEthnics',
      allFactDisabilities: 'curriculumVitae/allFactDisabilities',
      allFactReligions: 'curriculumVitae/allFactReligions',
      allFactProvinces: 'curriculumVitae/allFactProvinces',
      allFactDistricts: 'curriculumVitae/allFactDistricts',
      allFactWards: 'curriculumVitae/allFactWards',
      allFactDistrictsContact: 'curriculumVitae/allFactDistrictsContact',
      allFactWardsContact: 'curriculumVitae/allFactWardsContact',
      allFactDistrictsPermanentResidence: 'curriculumVitae/allFactDistrictsPermanentResidence',
      allFactWardsPermanentResidence: 'curriculumVitae/allFactWardsPermanentResidence',
      allFactNationalities: 'curriculumVitae/allFactNationalities',
      allGenders: 'gender/listData',
    }),
    hasLearnPartySelect() {
      return HAS_LEARN_PARTY.map(item => ({ value: item.value, label: item.label }))
    },
  },
  watch: {
    async studentInfo(val) {
      if (val) {
        this.isLoading = false
        try {
          await Promise.all([
            this.getFactEthnics(),
            this.getFactDisabilities(),
            this.getFactReligions({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
            this.getFactProvinces({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
            this.getFactNationalities({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
            this.getGenders({}),
            this.getDistrict({ province_code: this.studentInfo.province_code }),
            this.getWard({
              province_code: this.studentInfo.province_code,
              district_code: this.studentInfo.district_code,
            }),
            this.getDistrictContact({ province_code: this.studentInfo.province_code_contact }),
            this.getWardContact({ province_code: this.studentInfo.province_code_contact, district_code: this.studentInfo.district_code_contact }),
            this.getDistrictPermanentResidence({ province_code: this.studentInfo.province_code_permanent_residence }),
            this.getWardPermanentResidence({ province_code: this.studentInfo.province_code_permanent_residence, district_code: this.studentInfo.district_code_permanent_residence }),
          ])

          if (!this.studentInfo.path_avatar) {
            this.ulrAvatar = require('@/assets/images/avatars/default_avatar.jpg')
          } else {
            const resLink = await this.getLinkAvatar(this.studentInfo.path_avatar)
            if (resLink && resLink.isSuccessful) {
              this.ulrAvatar = resLink.data
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
  methods: {
    ...mapActions({
      getDataExport: 'curriculumVitae/downloadExport',
      getFactEthnics: 'curriculumVitae/getFactEthnics',
      getFactDisabilities: 'curriculumVitae/getFactDisabilities',
      getFactReligions: 'curriculumVitae/getFactReligions',
      getFactProvinces: 'curriculumVitae/getFactProvinces',
      getFactNationalities: 'curriculumVitae/getFactNationalities',
      updateStudentInfo: 'curriculumVitae/updateStudentInfo',
      getDistrict: 'curriculumVitae/getDistrict',
      getWard: 'curriculumVitae/getWard',
      getDistrictContact: 'curriculumVitae/getDistrictContact',
      getWardContact: 'curriculumVitae/getWardContact',
      getDistrictPermanentResidence: 'curriculumVitae/getDistrictPermanentResidence',
      getWardPermanentResidence: 'curriculumVitae/getWardPermanentResidence',
      getGenders: 'gender/getGenders',
      getLinkAvatar: 'employee/getLinkAvatar',
    }),
    async onProvinceChange(event) {
      this.isLoading = true
	    try {
        this.studentInfo.province_code = event
        await this.getDistrict({ province_code: event })
        this.studentInfo.district_code = null
        this.studentInfo.ward_code = null
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onDistrictChange(event) {
      this.isLoading = true
	    try {
        this.studentInfo.district_code = event
        await this.getWard({ province_code: this.studentInfo.province_code, district_code: event })
        this.studentInfo.ward_code = null
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onProvinceContactChange(event) {
      this.isLoading = true
	    try {
        this.studentInfo.province_code_contact = event
        await this.getDistrictContact({ province_code: event })
        this.studentInfo.district_code_contact = null
        this.studentInfo.ward_code_contact = null
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onDistrictContactChange(event) {
      this.isLoading = true
	    try {
        this.studentInfo.district_code_contact = event
        await this.getWardContact({ province_code: this.studentInfo.province_code_contact, district_code: event })
        this.studentInfo.ward_code_contact = null
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onProvincePermanentResidenceChange(event) {
      this.isLoading = true
	    try {
        this.studentInfo.province_code_permanent_residence = event
        await this.getDistrictPermanentResidence({ province_code: event })
        this.studentInfo.district_code_permanent_residence = null
        this.studentInfo.ward_code_permanent_residence = null
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onDistrictPermanentResidenceChange(event) {
      this.isLoading = true
	    try {
        this.studentInfo.district_code_permanent_residence = event
        await this.getWardPermanentResidence({ province_code: this.studentInfo.province_code_permanent_residence, district_code: event })
        this.studentInfo.ward_code_permanent_residence = null
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportHoSo() {
      this.isLoading = true
	    try {
        await this.getDataExport(this.filter)

        const a = document.createElement('a')
        document.body.appendChild(a)
        const blob = new Blob([this.dataExport.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = `ho_so_sinh_vien_${this.studentInfo.student_name}.xlsx`
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onUpdateStudent() {
      this.isLoading = true
      try {
        const response = await this.updateStudentInfo(this.studentInfo)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
